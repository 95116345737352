@import "../modules/mixins";
@import "../modules/colors";
@import "../modules/variables";

@import "../vendor/venobox/venobox";

.feature-category {
  em, i {
    font-style: italic;
  }
  sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }
  sup {
    top: -0.5em;
  }
  sub {
    bottom: -0.25em;
  }
  .hidden {
    display: none;
  }
  .image-left,
  .image-right,
  .image-center {
    margin: 1% 0;
    width: 40%;
  }
  .image-left {
    float: left;
    margin-right: 1%;
  }
  .image-right {
    float: right;
    margin-left: 1%;
  }
  .image-center {
    margin-bottom: 2%;
    width: 100%;
  }
  .normal-heading {
    font-family: inherit;
    text-transform: inherit;
  }
  .inactive {
    background-color: #fee;
  }
  .recipe-box {
    margin-bottom: 40px;
    padding-bottom: 0;
  }
  .recipe-box h4 {
    color: $headings-color;
    margin-bottom: 10px;
  }
  .recipe-box > .clearfix {
    background-color: white;
    padding: 20px 20px 0 20px;
  }
  .recipe-box a {
    display: block;
    float: left;
    font-size: 15px;
    line-height: inherit;
    padding: 0;
  }

  .healthy-snack-swaps {
    margin: 0 -4% 10px -5%;
    width: 108%;
    max-width: 108%;
  }

  .teasers  {
    h4 {
      font-weight: 700;
      a {
        border-bottom: 2px solid $link-color;
        display: inline-block;
        padding-bottom: 2px;
        margin-bottom: 6px;
        &:hover {
          border-bottom-color: transparent;
          text-decoration: none;
        }
      }
    }
    p {
      color: $steel;
    }
  }

  .ptn-stacked-nav.no-arrows {
     h4,
     h5 {
      background-color: inherit;
      &.active {
        background-color: rgba(255,255,255,.7);
        color: #000;
      }
    }
    h5>a {
      padding-left: 7px;
    }
    ul>li>a {
      padding: 0 15px 0 30px;
    }
  }

  .references {
    padding-bottom: 0;
    a {
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
      word-break: break-word;
      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;
    }
  }

  @include respond-min($screen-md-min) {
    .floating-sidebar {
      float: right;
      margin: 0 0 20px 20px;
      max-width: 320px;
      width: 40%;
      > :last-child {
        margin-bottom: 0;
      }
    }
  }

}
